import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "@mui/material";

function ExportExcel({ fileName, table }) {
  function tableToObj(table) {
    var trs = table.rows,
      trl = trs.length,
      i = 0,
      j = 0,
      keys = [],
      obj,
      ret = [];

    for (; i < trl; i++) {
      if (i == 0) {
        for (; j < trs[i].children.length; j++) {
          keys.push(trs[i].children[j].innerHTML);
        }
      } else {
        obj = {};
        for (j = 0; j < trs[i].children.length; j++) {
          obj[keys[j]] = trs[i].children[j].innerHTML;
        }
        ret.push(obj);
      }
    }
    exportToExcel(ret);
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async (ret) => {
    console.log(ret);
    const ws = XLSX.utils.json_to_sheet(ret);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div className="botaoDireita">
      <Button
        type="submit"
        variant="contained"
        onClick={() => {
            tableToObj(table);
        }}
      >
        Exportar
      </Button>
    </div>
  );
}

export default ExportExcel;
