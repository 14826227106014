
import './App.css';
import Form from './components/Form';
import Loading from './components/Loading'
import Inicio from './components/Inicio';

function App() {
  return (
    <div className="App">
      <Form />
    </div>
  );
}

export default App;
