import axios from "axios";

//Retorna as tabelas criadas no SIGEOS
export const getGrids = async () => {
  //const url = "http://localhost:8092/sigeos/grids";
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids";
  return await axios.get(url); 
}

//Consulta as informações da tabela de serviços da OS. 
export const getGridData = async (gridId, issueKey) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/grids/servico/"+gridId+"/"+issueKey;
  //const url = "http://localhost:8092/sigeos/grids/servico/"+gridId+"/"+issueKey;
  return await axios.get(url);
}

//Consulta ignorando os status todas as OS's onde a coluna quantidade seja maior que 0 na tabela de serviços
// ...dentro do período de resolução da OS
export const getListIssues = async (fim) => {
  const url = "https://backend-sigeos.herokuapp.com/sigeos/cloud/search/";
  //const url = "http://localhost:8092/sigeos/cloud/search/";
  const query = "created <= '"+fim+" 23:59' and status != Fechado and status != Cancelado"
  const startAt = 0;
  const maxResults = 100;
  return await axios.post(url, {jql: query, startAt:startAt, maxResults:maxResults});
}