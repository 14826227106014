import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExportExcel from "./ExportExcel";


function Tabela({ dados }) {
  const [info, setInfo] = useState();

  useEffect(() => {
    setInfo(dados);
    //console.log("tabela");
    console.log(dados);
  }, [dados]);

  function onMaterialHandler(servico){
    var total = 0;
    for(var i = 0; i < servico.length ; i++){
      total = total + servico[i].columns.vlr;
    }
    return total.toFixed(2);
  }

  function onMaterialNameHandler(servico){
    //console.log("material");
    if (servico != null) {
      
      //console.log(material);
      return servico.label;
      
    }
  }

  function onMaterialVlrHandler(servico){
    //console.log("material");
    if (servico != null) {
      //console.log(material);
      return servico.valor_unitario.toFixed(2);
    }
  }

  function onMaterialUndHandler(servico){
    //console.log("material");
    if (servico != null) {
      //console.log(material);
      return servico.unidade;     
    }
  }

  function onComplexo(issue){
    if(issue.fields.customfield_10065 != null) {
      //console.log(issue);
      
      

      return issue.fields.customfield_10065.value + " / " + getEdificio(issue.fields);
    }
    
  }

  function especialidadeHandler(issue){
    if(issue.fields.customfield_10229 != null) {
        return issue.fields.customfield_10229.value;
      }else {
        return "";
      }
  }

  function getEdificio(complexo){
    //customfield_10081 -> SGON
    //customfield_10079 -> PCDF
    //customfield_10078 -> DASG/DITRAN
    //customfield_10080 -> REGIONAL NORTE
    //customfield_10082 -> UNIDADES DIVERSAS
    //console.log(complexo);
    
    switch (complexo.customfield_10065.value){

      
      case "COMPLEXO PCDF": return complexo.customfield_10079.value;

      case "COMPLEXO SGON": return complexo.customfield_10081.value;

      case "COMPLEXO DASG/DITRAN": return complexo.customfield_10078.value;

      case "COMPLEXO REGIONAL NORTE": return complexo.customfield_10080.value;

      case "UNIDADES DIVERSAS": return complexo.customfield_10082.value;

      default: return "";
      
    }
  }

  function dateHandler(date) {

    if(date != null){
      var newDate = new Date(date);
      return newDate.toLocaleDateString();
    }

  }


    
  return (
    <div>
      <ExportExcel fileName={"Relatorio de Serviços"} table={document.getElementsByTagName('table')[0]}/>
      <TableContainer component={Paper} elevation={20}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="">Data de Criação</TableCell>
              <TableCell align="">Data de Resolução</TableCell>
              <TableCell align="">Nº OS</TableCell>
              <TableCell align="">Local</TableCell>
              <TableCell align="">Resumo</TableCell>
              <TableCell align="">Serviço</TableCell>
              <TableCell align="">Qtd.</TableCell>
              <TableCell align="">UND.</TableCell>
              <TableCell>Vlr. Unit.</TableCell>
              <TableCell>Vlr. Total</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Especialidade</TableCell>
              <TableCell align="right">Tipo</TableCell>
            </TableRow>
          </TableHead>
          {dados.map((issue) =>
              <TableBody>
                {issue.servico.map((m) =>     
                    <TableRow>
                      <TableCell>{dateHandler(issue.fields.created)}</TableCell>
                      <TableCell>{dateHandler(issue.fields.resolutiondate)}</TableCell>
                      <TableCell>{issue.key}</TableCell>
                      <TableCell>{onComplexo(issue)}</TableCell>
                      <TableCell>{issue.fields.summary}</TableCell>
                      <TableCell>{onMaterialNameHandler(m.columns.servico)}</TableCell>
                      <TableCell>{m.columns.qtd}</TableCell>
                      <TableCell>{onMaterialUndHandler(m.columns.servico)}</TableCell>
                      <TableCell>{"R$ " + onMaterialVlrHandler(m.columns.servico)}</TableCell>
                      <TableCell>{"R$ " + (onMaterialVlrHandler(m.columns.servico) * m.columns.qtd).toFixed(2)}</TableCell>
                      <TableCell align="right">{issue.fields.status.name}</TableCell>
                      <TableCell>{especialidadeHandler(issue)}</TableCell>
                      <TableCell align="right">{issue.fields.issuetype.name}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            )}
          
          
         
        </Table>
      </TableContainer>
    </div>
  );
}

export default Tabela;
